import { Box, Card, Chip, CircularProgress, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { useSnackbar } from "notistack";
import { parentChildList, parentChildLogin } from "src/DAL/Login/Login";
import { s3baseUrl } from "src/config/config";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { user } from "src/assets";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
const ProgrammesImgStyle = styled("img")({
  top: 0,
  width: "150px",
  height: "150px",
  //   objectFit: "cover",
});

export default function UserScreens() {
  const {
    userInfo,
    handleUserInfo,
    handleNotificationsCount,
    handleGeneralSettingData,
    handleParentChild,
    START_SOCKET_FORCEFULLY,
  } = useContentSetting();
  const [parent, setParent] = useState({});
  const [children, setChildren] = useState([]);
  const classes = useStyles();
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);

  const handleParentChildList = async () => {
    setIsLoading(true);
    const result = await parentChildList(userInfo?._id);
    if (result.code === 200) {
      // console.log(result, "parent child list");
      setParent(result.parent);
      setChildren(result.children);
      let users = [];
      result.children?.map((item) => {
        users.push(item);
      });
      users.push(result.parent);
      localStorage.setItem(`parent_childs`, JSON.stringify(users));
      handleParentChild(users);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.data?.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handleDashboard = async (data) => {
    setIsLoading(true);
    let postData = {
      email: data?.email,
    };
    const result = await parentChildLogin(postData);
    if (result.code === 200) {
      START_SOCKET_FORCEFULLY(result.member._id);
      console.log(result, "login with email");
      localStorage.setItem("token", result.token);
      localStorage.setItem(`user_data`, JSON.stringify(result.member));

      handleGeneralSettingData(
        result?.member?.gratitude_coins +
          result?.member?.meditation_coins +
          result?.member?.affirmation_coins +
          result?.member?.challenges_coins +
          result?.member?.goal_coins_count
      );
      handleNotificationsCount(result?.unread_notification_count);
      handleUserInfo(result?.member);
      navigate("/dashboard", { replace: true });
      setIsLoading(false);
      // enqueueSnackbar("valid Credentials", { variant: "error" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  useEffect(() => {
    handleParentChildList();
  }, []);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <div className="container">
      <div className="row user-screens">
        <div
          className="col-lg-2 col-md-4 col-sm-12 mt-4 d-flex flex-column justify-content-center align-items-center"
          onClick={() => handleDashboard(parent)}
        >
          <p className="title color-text user-screen-title"> Parent / School</p>
          <Card
            className="programm-card secondary-background"
            sx={{ cursor: "pointer", position: "relative" }}
          >
            <Box sx={{ position: "relative", cursor: "pointer" }}>
              <ProgrammesImgStyle
                alt={parent.first_name}
                src={
                  parent.profile_image ? s3baseUrl + parent.profile_image : user
                }
              />
            </Box>
          </Card>
          <Stack spacing={2} className="programme-content">
            <h4 className="title color-text">
              {parent.first_name} {parent.last_name}{" "}
            </h4>
          </Stack>
        </div>

        {children.length > 0 &&
          children.map((value, index) => (
            <div
              className="col-lg-2 col-md-4 col-sm-12 mt-4 d-flex flex-column justify-content-center align-items-center"
              onClick={() => handleDashboard(value)}
            >
              {/* <h4 className="title color-text"> Child {index + 1}</h4> */}
              <p className="title color-text user-screen-title">
                {" "}
                Child / Teacher {index + 1}
              </p>

              <Card
                className="programm-card secondary-background"
                sx={{ cursor: "pointer", position: "relative" }}
              >
                <Box sx={{ position: "relative", cursor: "pointer" }}>
                  <ProgrammesImgStyle
                    alt={value.first_name}
                    src={
                      value.profile_image
                        ? s3baseUrl + value.profile_image
                        : user
                    }
                  />
                </Box>
              </Card>
              <Stack spacing={2} className="programme-content">
                <h4 className="title color-text">
                  {" "}
                  {value.first_name} {value.last_name}{" "}
                </h4>
              </Stack>
            </div>
          ))}
      </div>
    </div>
  );
}
