import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Check from "@mui/icons-material/Check";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { Card, IconButton } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Popup from "./Popup";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const QontoConnector = styled(StepConnector)(() => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#9f00ff",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#784af4",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: "#bbbbc0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled("div")(({ theme }) => ({
  color: "#eaeaf0",
  display: "flex",
  height: 22,
  alignItems: "center",
  "& .QontoStepIcon-completedIcon": {
    color: "9f00ff",
    zIndex: 1,
    fontSize: 35,
  },
  "& .QontoStepIcon-circle": {
    width: 11,
    height: 11,
    borderRadius: "50%",
    backgroundColor: "#bbbbc0",
  },
  "& .QontoStepIcon-circle-active": {
    width: 11,
    height: 11,
    borderRadius: "50%",
    backgroundColor: "#cf7fff",
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className, onClick } = props;

  return (
    <QontoStepIconRoot
      ownerState={{ active }}
      className={className}
      onClick={active || completed ? onClick : null}
      style={{ cursor: active || completed ? "pointer" : "default" }}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : active ? (
        <div className="QontoStepIcon-circle-active" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  completed: PropTypes.bool,
  onClick: PropTypes.func,
};

const steps = [
  "Start 1",
  "Step 2",
  "Step 3",
  "Step 4",
  "Step 5",
  "Step 6",
  "Step 7",
  "Step 8",
  "Step 9",
  "Step 10",
  "Step 11",
  "Step 12",
  "Finish 13",
];

export default function CustomizedSteppers({
  stagesList,
  firstStage,
  getProgrammesDetail,
}) {
  const [openPopup, setOpenPopup] = useState(false);
  const [selectedStage, setSelectedStage] = useState();
  const [activeStep, setActiveStep] = useState();
  const [scrollEnd, setScrollEnd] = useState(false); // Track scroll position

  const handleOpen = (value) => {
    setSelectedStage(value);
    setOpenPopup(true);
  };

  const handleCloseImage = () => setOpenPopup(false);

  const handleScroll = () => {
    const stepperContainer = document.getElementById("stepper-container");
    if (stepperContainer) {
      const isAtEnd =
        Math.ceil(stepperContainer.scrollLeft + stepperContainer.clientWidth) >=
        stepperContainer.scrollWidth;
      setScrollEnd(isAtEnd);
    }
  };

  const handleScrollClick = () => {
    const stepperContainer = document.getElementById("stepper-container");
    if (stepperContainer) {
      stepperContainer.scrollTo({
        left: scrollEnd ? 0 : stepperContainer.scrollWidth,
        behavior: "smooth",
      });
    }
  };
  useEffect(() => {
    if (activeStep > 7) {
      const stepperContainer = document.getElementById("stepper-container");
      if (stepperContainer) {
        stepperContainer.scrollTo({
          left: stepperContainer.scrollWidth,
          behavior: "smooth",
        });
      }
    }
  }, [activeStep]);
  useEffect(() => {
    if (firstStage) {
      const index = stagesList.findIndex((stage) => stage._id === firstStage);
      setActiveStep(index);
    } else {
      setActiveStep(stagesList.length);
    }
  }, [firstStage]);
  return (
    <div className="row" style={{ position: "relative" }}>
      <div className="col-12">
        <Card
          className="progress-card"
          style={{
            overflowX: stagesList.length > 8 ? "auto" : "visible",
            position: "relative",
            padding: "39px 15px 22px 15px",
          }}>
          <Stack
            sx={{
              width: "100%",
              whiteSpace: "nowrap",
              overflowX: "auto",
              // padding: "10px",
            }}
            id="stepper-container"
            onScroll={handleScroll}>
            <Stepper
              alternativeLabel
              // activeStep={3}
              activeStep={activeStep}
              connector={<QontoConnector />}
              style={{
                width:
                  stagesList.length > 8
                    ? `${stagesList.length * 130}px`
                    : "100%",
                padding: "5px",
              }}>
              {stagesList.map((label, index) => (
                <Step key={index} sx={{ marginBottom: "18px" }}>
                  <StepLabel
                    StepIconComponent={(props) => (
                      <QontoStepIcon
                        {...props}
                        onClick={() => handleOpen(label)}
                      />
                    )}>
                    <span
                      className="step-label"
                      onClick={
                        index <= activeStep ? () => handleOpen(label) : null
                      }
                      style={{
                        cursor: index <= activeStep ? "pointer" : "default",
                      }}>
                      {label.name}
                    </span>
                    {index <= activeStep && (
                      <InfoOutlinedIcon
                        onClick={() => handleOpen(label)}
                        sx={{
                          width: "17px",
                          height: "17px",
                          color: "#828286",
                          marginLeft: "4px",
                          cursor: "pointer",
                        }}
                      />
                    )}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Stack>
          {stagesList.length > 8 && (
            <IconButton
              onClick={handleScrollClick}
              sx={{
                position: "absolute",
                bottom: 107,
                right: 9,
                opacity: 7,
                backgroundColor: "#f0f0f0",
                "&:hover": {
                  backgroundColor: "#f0f0f0",
                },
              }}>
              {scrollEnd ? <ArrowBackIcon /> : <ArrowForwardIcon />}
            </IconButton>
          )}
        </Card>
      </div>
      <Popup
        open={openPopup}
        handleClose={handleCloseImage}
        selectedStage={selectedStage}
        getProgrammesDetail={getProgrammesDetail}
      />
    </div>
  );
}
