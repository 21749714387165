import Modal from "@mui/material/Modal";
import { TextField, CardContent, Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { goalLogo, goalSide } from "src/assets";
import { add_goal, edit_goal } from "src/DAL/Goals/Goals";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import { LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import moment from "moment";

const useStyles = makeStyles(() => ({
  notchedOutline: {
    borderColor: "#000 !important",
    border: "1px solid",
  },
}));
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  maxWidth: "100%",
  maxHeight: "100vh",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
  borderRadius: 1,
  overflow: "auto",
};
export default function AddGoal({
  openCreateModal,
  setOpenCreateModal,
  apiCall,
  goalsValue,
  editGoal,
  setEditGoal,
}) {
  const [inputs, setInputs] = useState({
    class: "",
    goal: "",
    description: "",
  });
  console.log(goalsValue, "goalsvalue.............");
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [date, setDate] = React.useState(new Date());

  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const handleClose = () => {
    setOpenCreateModal(false);
    setInputs({
      class: "",
      goal: "",
      description: "",
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (inputs.class == "") {
      enqueueSnackbar("Class is Required", { variant: "error" });
      return;
    }
    if (inputs.class.length > 15) {
      enqueueSnackbar(
        "Maximum 15 characters are allowed in class including spaces",
        { variant: "error" }
      );
      return;
    }
    if (inputs.goal.length > 18) {
      enqueueSnackbar(
        "Maximum 18 characters are allowed in goal including spaces",
        { variant: "error" }
      );
      return;
    }
    if (inputs.goal == "") {
      enqueueSnackbar("Goal is Required", { variant: "error" });
      return;
    }
    if (inputs.description == "") {
      enqueueSnackbar("Description is Required", { variant: "error" });
      return;
    }
    setIsLoading(true);
    let postData = {
      class: inputs.class,
      goal: inputs.goal,
      description: inputs.description,
      target_date: date,
      // target_date
    };
    const result = await add_goal(postData);
    if (result.code == 200) {
      setInputs({
        class: "",
        goal: "",
        description: "",
      });
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
      apiCall();
      setOpenCreateModal(false);
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleUpdate = async (e) => {
    e.preventDefault();
    if (inputs.class == "") {
      enqueueSnackbar("Class is Required", { variant: "error" });
      return;
    }
    if (inputs.goal == "") {
      enqueueSnackbar("Goal is Required", { variant: "error" });
      return;
    }
    if (inputs.description == "") {
      enqueueSnackbar("Description is Required", { variant: "error" });
      return;
    }
    if (!date) {
      enqueueSnackbar("date is Required", { variant: "error" });
      return;
    }
    setIsLoading(true);
    let postData = {
      class: inputs.class,
      goal: inputs.goal,
      description: inputs.description,
      target_date: date,
    };
    const result = await edit_goal(goalsValue._id, postData);
    if (result.code == 200) {
      setInputs({
        class: "",
        goal: "",
        description: "",
      });
      setDate(goalsValue.target_date);
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
      setEditGoal(false);
      apiCall();
      setOpenCreateModal(false);
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  console.log(goalsValue, "nnnnnnnnn");
  useEffect(() => {
    if (editGoal) {
      setInputs((prevState) => ({
        ...prevState,
        ["class"]: goalsValue.class,
        ["goal"]: goalsValue.goal,
        ["description"]: goalsValue.description,
      }));
      setDate(new Date(goalsValue.target_date));
    }
  }, [editGoal]);
  const handleChangeDate = (newValue) => {
    let todayDate = moment(newValue).format("YYYY-MM-DD");
    let dateType = todayDate.toString();
    //console.log(typeof dateType, "dateType");
    setDate(newValue);
  };

  return (
    <div>
      <Modal
        open={openCreateModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="goal-modal-theme">
          <div className="d-flex">
            <div className="row">
              <div
                className="col-3 mt-4 mt-lg-0 align-items-center d-flex"
                style={{ width: "20%" }}
              >
                <img
                  className="mt-4 mt-lg-0"
                  src={goalSide}
                  alt="Left Aligned"
                  style={{ width: "auto", height: "auto" }}
                />
              </div>
              <div className="col-9 align-items-center">
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <img
                    src={goalLogo}
                    alt="Logo"
                    style={{ width: 150, height: 70 }}
                  />
                </Box>
                <Box className="d-flex">
                  <CardContent>
                    <TextField
                      fullWidth
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                        // inputProps: {
                        //   maxLength: 5, // Limits to 15 characters including spaces
                        // },
                      }}
                      required
                      className="custom-goal-field"
                      label="Class"
                      variant="outlined"
                      margin="normal"
                      name="class"
                      value={inputs.class}
                      onChange={handleChange}
                      error={inputs.class.length > 15 ? true : false}
                      helperText={
                        inputs.class.length > 15
                          ? "Maximum 15 characters allowed including spaces."
                          : ""
                      } // Shows error message if error is true
                    />
                    <div className="mt-2">
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          label="Target Date*"
                          inputFormat="dd/MM/yyyy"
                          value={date}
                          onChange={handleChangeDate}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  "& .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "black",
                                  },
                                  "&:hover .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "black",
                                  },
                                  "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                    {
                                      borderColor: "black",
                                    },
                                },
                                "& .MuiInputLabel-root": {
                                  color: "black",
                                },
                                "& .MuiInputLabel-root.Mui-focused": {
                                  color: "black",
                                },
                              }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </div>

                    <TextField
                      required
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                      fullWidth
                      label="Goal"
                      className="custom-goal-field"
                      variant="outlined"
                      margin="normal"
                      name="goal"
                      value={inputs.goal}
                      onChange={handleChange}
                      error={inputs.goal.length > 18 ? true : false}
                      helperText={
                        inputs.goal.length > 18
                          ? "Maximum 18 characters allowed including spaces."
                          : ""
                      } // Shows error message if error is true
                    />

                    <TextField
                      required
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                      sx={{ marginTop: "8px" }}
                      fullWidth
                      label="Description"
                      className="custom-goal-field"
                      variant="outlined"
                      margin="normal"
                      multiline
                      rows={3}
                      name="description"
                      value={inputs.description}
                      onChange={handleChange}
                    />
                  </CardContent>
                </Box>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 text-end">
              <button
                className="small-contained-button-search"
                onClick={handleClose}
              >
                Cancel
              </button>
              <button
                className="small-contained-button-search"
                style={{ marginLeft: "10px" }}
                onClick={editGoal ? handleUpdate : handleSubmit}
              >
                {isLoading ? "Saving..." : "Save"}
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
