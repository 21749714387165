import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Icon } from "@iconify/react";
import { IconButton } from "@mui/material";
import closeFill from "@iconify/icons-eva/close-fill";
import { get_root_value } from "src/utils/domUtils";
import { useSnackbar } from "notistack";
import { complete_stage } from "src/DAL/Programmes/StageComplete";
import { useState } from "react";
import CustomConfirmation from "../CustomConfirmation";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "90%",
  minWidth: "500px",
  flexDirection: "column",
  padding: "20px",
  bgcolor: get_root_value("--sidebars-background-color"),
  border: "1px solid #fff",
  boxShadow: 24,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "15px",
};

export default function Popup({
  open,
  handleClose,
  selectedStage,
  getProgrammesDetail,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [openComplete, setOpenComplete] = useState(false);
  const handleAgreeComplete = (value) => {
    setOpenComplete(true);
  };
  const handleSubmit = async (e) => {
    setOpenComplete(false);
    setIsLoading(true);
    e.preventDefault();
    const result = await complete_stage(selectedStage._id);
    if (result.code == 200) {
      getProgrammesDetail();
      handleClose();
      setIsLoading(false);

      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  return (
    <div>
      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description">
        <Box sx={style}>
          <IconButton onClick={handleClose} className="mui-btn-close-icon">
            <Icon icon={closeFill} width={20} height={20} />
          </IconButton>
          <div className="row">
            <h3 className="d-flex justify-content-center">
              {selectedStage?.name}
            </h3>
            <p>{selectedStage?.description}</p>
          </div>
          {!selectedStage?.completed_status && (
            <div className="row">
              <div className="col-12 text-end">
                <button
                  className="small-contained-button-search"
                  onClick={handleAgreeComplete}>
                  {isLoading ? "Completing..." : "Complete"}
                </button>
              </div>
            </div>
          )}
        </Box>
      </Modal>
      <CustomConfirmation
        open={openComplete}
        setOpen={setOpenComplete}
        title={"Are you sure you want to Complete this ?"}
        handleAgree={handleSubmit}
      />
    </div>
  );
}
