import { invokeApi } from "../../bl_libs/invokeApi";

export const get_saved_goals = async () => {
  const requestObj = {
    path: `api/goal_growth/list_uncompleted`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const get_completed_goals = async () => {
  const requestObj = {
    path: `api/goal_growth/completed`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const complete_goal = async (id) => {
  const requestObj = {
    path: `api/goal_growth/complete/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const delete_goal = async (id) => {
  const requestObj = {
    path: `api/goal_growth/delete_goal/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const add_goal = async (data) => {
  const requestObj = {
    path: `api/goal_growth/add_goal`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const edit_goal = async (id, data) => {
  const requestObj = {
    path: `api/goal_growth/update_goal/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
