import React, { useState, useEffect, useRef } from "react";
import {
  useNavigate,
  useParams,
  Link as RouterLink,
  useLocation,
} from "react-router-dom";
import { useSnackbar } from "notistack";
import { IconButton, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ProgrammDetailTab from "../../components/_dashboard/programmes/ProgrammDetailTab";
import { programmDetail } from "../../DAL/Programmes/Programmes";
import { htmlDecode } from "../../utils/convertHtml";
import { s3baseUrl } from "../../config/config";
import { styled } from "@mui/material/styles";
import { ReactVideoDurationPlayer } from "src/components";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import CustomizedSteppers from "src/components/Progress/Progress";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

function ProgrammesDetail(props) {
  const bodyRef = useRef(null);

  const params = useParams();
  const navigate = useNavigate();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const { handleVideoList } = useContentSetting();
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [programmesDetail, setProgrammesDetail] = useState({});
  const [lessonsList, setLessonsList] = useState([]);
  const [stagesList, setStagesList] = useState([]);
  const [resourcesList, setResourcesList] = useState([]);
  const [reviewsList, setReviewsList] = useState([]);
  const [recordingList, setRecordingList] = useState([]);
  const [programSection, setProgramSection] = useState([]);
  const [studySession, setStudySession] = useState([]);
  const [firstStage, setFirstStage] = useState("");
  const [videoProgressData, setVideoProgressData] = useState([]);
  const ProgrammesImgStyle = styled("img")({
    width: "100%",
    margin: "auto",
    objectFit: "cover",
  });

  const getProgrammesDetail = async () => {
    setIsLoading(true);
    const result = await programmDetail(params.slug);
    if (result.code === 200) {
      let data = [
        {
          recording_id: result.program?._id,
          video_duration: result.program?.video_duration,
          total_video_duration: result.program?.total_video_duration,
          is_complete: result.program?.is_complete,
        },
      ];
      // setMessage(result?.program?.custom_design || ""); // Safely set the message
      setVideoProgressData(data);
      handleVideoList(data);
      // localStorage.setItem("video_list", JSON.stringify(data));
      localStorage.setItem("video_type", "program");
      setProgrammesDetail(result.program);
      setLessonsList(result.lesson);
      setFirstStage(result?.current_stage_to_complete);
      setStagesList(result?.stages);
      setResourcesList(result.program_document);
      setReviewsList(result.program_review);
      setRecordingList(result.recording_list);
      setProgramSection(result.program_section);
      setStudySession(result.study_session);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const get_programme_image = () => {
    if (programmesDetail.program_lock_status === true) {
      return programmesDetail?.locked_program_info?.image;
    } else {
      return programmesDetail?.program_images?.thumbnail_1;
    }
  };
  const get_programme_video = () => {
    if (programmesDetail.program_lock_status === true) {
      return programmesDetail.locked_program_info.video_url;
    } else {
      return programmesDetail.video_url;
    }
  };
  const get_audio_url = () => {
    if (programmesDetail.program_lock_status === true) {
      return "";
    } else {
      return programmesDetail.audio_file;
    }
  };

  const get_detailed_description = () => {
    if (programmesDetail.program_lock_status === true) {
      return programmesDetail.locked_program_info.detailed_description;
    } else {
      return programmesDetail.detailed_description;
    }
  };
  useEffect(() => {
    getProgrammesDetail();
  }, []);

  // useEffect(() => {
  //   if (message) {
  //     const parser = new DOMParser();
  //     const htmlDoc = parser.parseFromString(message, "text/html");
  //     const headScripts = htmlDoc.querySelectorAll("script");
  //     headScripts.forEach((script) => {
  //       const newScript = document.createElement("script");
  //       if (script.src) {
  //         newScript.src = script.src;
  //       } else {
  //         newScript.textContent = script.textContent;
  //       }
  //       document.body.appendChild(newScript);
  //     });

  //     // Append styles
  //     const headStyles = htmlDoc.querySelectorAll("style");
  //     headStyles.forEach((style) => {
  //       const newStyle = document.createElement("style");
  //       newStyle.textContent = style.textContent;
  //       document.head.appendChild(newStyle);
  //     });

  //     // Set body content
  //     const bodyContent = htmlDoc.body.innerHTML;
  //     if (bodyRef.current) {
  //       bodyRef.current.innerHTML = bodyContent;
  //     }
  //   }
  // }, [message]);
  useEffect(() => {
    let data = [
      {
        recording_id: programmesDetail?._id,
        video_duration: programmesDetail?.video_duration,
        total_video_duration: programmesDetail?.total_video_duration,
        is_complete: programmesDetail?.is_complete,
      },
    ];
    localStorage.setItem("video_list", JSON.stringify(data));
  }, [programmesDetail]);
  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  // if (!bodyRef && isLoading) {
  //   return <CircularProgress className={classes.loading} color="primary" />;
  // }
  return (
    <div className="container">
      <div className="row mobile-margin display-flex">
        <div className="col-12">
          <IconButton
            className="back-screen-button"
            onClick={() => navigate("/programmes")}>
            <ArrowBackIcon />
          </IconButton>
          <button
            className="small-contained-button float-end mt-1"
            onClick={() => navigate(`/programmes/${params.slug}/lessons`)}>
            View Lessons
          </button>
        </div>
      </div>
      <div className="row section-space">
        <div className="col-12">
          <h1>
            {location.state
              ? htmlDecode(location.state.title)
              : htmlDecode(programmesDetail.title)}
          </h1>
        </div>
      </div>
      <div className="mt-2 mb-4">
        {stagesList && stagesList.length > 0 && (
          <CustomizedSteppers
            stagesList={stagesList}
            firstStage={firstStage}
            getProgrammesDetail={getProgrammesDetail}
          />
        )}
      </div>

      <div className="row">
        <section className="container top-section-video mb-lg-3">
          <div className="row pt-70">
            <div className="col-2"></div>
            <div className="col-8">
              {get_programme_video() ? (
                <ReactVideoDurationPlayer
                  url={get_programme_video()}
                  type={"program"}
                  value={programmesDetail}
                  setVideoProgressData={setVideoProgressData}
                  videoProgressData={videoProgressData}
                />
              ) : get_programme_image() ? (
                <ProgrammesImgStyle src={s3baseUrl + get_programme_image()} />
              ) : (
                ""
              )}
            </div>
            <div className="col-2"></div>
          </div>
        </section>
        <div className="col-12 text-end">
          {programmesDetail.is_complete ? (
            // <Icon
            //   icon="mdi:tick-circle"
            //   width={30}
            //   height={30}
            //   color="primary"
            // />
            <></>
          ) : (
            <></>
          )}
        </div>
        {get_audio_url() && (
          <div className="col-12 section-space">
            <audio
              controlsList="nodownload"
              className="w-100"
              src={s3baseUrl + get_audio_url()}
              controls
            />
          </div>
        )}
        {/* {bodyRef && <div ref={bodyRef}></div>} */}
        <div className="col-12 section-space set-image-center">
          <div
            dangerouslySetInnerHTML={{
              __html: get_detailed_description(),
            }}></div>
        </div>
      </div>
      {/* commentt */}

      <div className="row section-space">
        <div className="col-12">
          <ProgrammDetailTab
            lessons={lessonsList}
            resources={resourcesList}
            reviews={reviewsList}
            recordingList={recordingList}
            programSection={programSection}
            studySession={studySession}
            programLockStatus={programmesDetail.program_lock_status}
            programSlug={programmesDetail.program_slug}
          />
        </div>
      </div>
    </div>
  );
}

export default ProgrammesDetail;
